import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useBrands } from 'react-omnitech-api'
import BrandsView from './brands-view'
import getBrands from '../../helpers/get-brands-list'
import {
  useThemeConfig,
} from '../../hook'

const BrandsController = () => {
  const [brandsData, setbrandsData] = useState([])
  const [pageReady] = useState(true)
  const { t } = useTranslation()
  const { getContentGroup } = useThemeConfig()
  const seoTitle = t('screens.brands.seo.title', '')
  const { brands } = useBrands()

  const bannerContentGroupProps = useMemo(() => (
    getContentGroup('config.pages.brands.banner')
  ), [getContentGroup])

  useEffect(() => {
    const memuObject = getBrands(brands)
    setbrandsData(memuObject)
  }, [brands])

  const viewProps = {
    pageReady,
    seoTitle,
    brandsData,
    bannerContentGroupProps,
  }

  return (
    <BrandsView {...viewProps} />
  )
}

export default BrandsController
